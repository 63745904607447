<template>
  <div class="Private">
    <a-input-search
      v-model:value="value"
      placeholder="请输入"
      enter-button="Search"
      @search="requestChange"
      style="width: 420px; margin-bottom: 15px"
    >
      <template #addonBefore>
        <a-select v-model:value="value2" style="width: 120px">
          <a-select-option value="1">客户名称</a-select-option>
          <a-select-option value="2">电话号码</a-select-option>
          <a-select-option value="3">联系人</a-select-option>
        </a-select>
      </template>
    </a-input-search>
    <a-form>
      <div class="top">
        <a-form-item label="营销任务">
          <a-select
            placeholder="请选择"
            v-model:value="uuid"
            @change="requestChange"
            allowClear
          >
            <a-select-option
              v-for="item in uuidArr"
              :value="item.uuid"
              :key="item.uuid"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="最后通话时长">
          <a-input
            suffix="秒"
            @blur="requestChange"
            v-model:value="bill_min"
          />~<a-input
            suffix="秒"
            v-model:value="bill_max"
            @blur="requestChange"
          />
        </a-form-item>
      </div>
      <a-form-item label="客户类型">
        <a-checkbox-group v-model:value="result_type" @change="requestChange">
          <a-checkbox value="2">A级(有明确意向)</a-checkbox>
          <a-checkbox value="3">B级(可能有意向)</a-checkbox>
          <a-checkbox value="4">C级(明确拒绝)</a-checkbox>
          <a-checkbox value="5">D级(用户忙)</a-checkbox>
          <a-checkbox value="6">E级(拨打失败)</a-checkbox>
          <a-checkbox value="7">F级(无效客户)</a-checkbox>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
    <div class="table-top">
      <div>全部{{ total }}个客户</div>
      <div>
        <a-dropdown style="margin-right: 20px">
          <template #overlay>
            <a-menu @click="daoRu">
              <a-menu-item key="1"> 单个导入 </a-menu-item>
              <a-menu-item key="2">批量导入 </a-menu-item>
            </a-menu>
          </template>
          <a-button type="primary">
            导入
            <DownOutlined />
          </a-button>
        </a-dropdown>
        <a-dropdown>
          <template #overlay>
            <a-menu @click="daoChu">
              <a-menu-item key="1"> 导出Excel </a-menu-item>
            </a-menu>
          </template>
          <a-button type="primary">
            导出
            <DownOutlined />
          </a-button>
        </a-dropdown>
        <a-button style="margin: 0 10px" @click="zhuanYi"> 转移 </a-button>
        <a-button style="margin: 0 10px" @click="deleteCustomer(1)">
          退回公海
        </a-button>
        <a-button @click="deleteCustomer"> 删除 </a-button>
        <a-dropdown>
          <template #overlay>
            <a-menu @click="genDuo">
              <a-menu-item key="1"> 添加至营销任务 </a-menu-item>
              <a-menu-item key="2"> 添加至人工外呼 </a-menu-item>
              <a-menu-item key="3"> 加入黑名单 </a-menu-item>
            </a-menu>
          </template>
          <a-button>
            更多
            <DownOutlined />
          </a-button>
        </a-dropdown>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :pagination="{
        current: current,
        total: total,
        pageSize: 9,
      }"
      @change="pageChange"
      rowKey="crm_customer_id"
    >
      <template #result_type="{ record }">
        {{ handleType(record.result_type) }}
      </template>
      <template #operation="{ record }">
        <a @click="callLog(record.crm_customer_id)" style="margin-right: 4px"
          >通话记录</a
        >|
        <a style="margin-right: 4px" @click="genJin(record.crm_customer_id)"
          >跟进记录</a
        >|
        <a @click="xinXi(record)">信息</a>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible1"
      :title="customer_id ? '编辑信息' : '导入客户'"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      :width="670"
      class="modalAdd"
      @cancel="formRest"
      @ok="addCustomer"
    >
      <a-form :model="formState" class="Form">
        <template v-for="item in custom_field" :key="item.id">
          <a-form-item :label="item.label" :required="item.req == 1">
            <div v-if="item.type === 'text'">
              <a-input
                placeholder="请输入"
                v-model:value="formState.systemData[item.fieldName]"
                v-if="item.style == 0"
              />
              <a-input
                placeholder="请输入"
                v-model:value="formState.templateData[item.fieldName]"
                v-else
              />
            </div>
            <div v-else-if="item.type === 'select'">
              <a-select
                placeholder="请选择"
                v-if="item.style == 0"
                v-model:value="formState.systemData[item.fieldName]"
              >
                <a-select-option
                  v-for="(item2, index) in item.val"
                  :key="index"
                  :value="item2.lab_id"
                  >{{ item2.name }}</a-select-option
                >
              </a-select>
              <a-select
                v-else
                placeholder="请选择"
                v-model:value="formState.templateData[item.fieldName]"
              >
                <a-select-option
                  v-for="(item2, index) in item.val"
                  :key="index"
                  :value="item2.lab_id"
                  >{{ item2.name }}</a-select-option
                >
              </a-select>
            </div>
            <div v-else-if="item.type === 'radio'">
              <a-radio-group
                v-if="item.style == 0"
                v-model:value="formState.systemData[item.fieldName]"
              >
                <a-radio
                  v-for="(item2, index) in item.val.split(',')"
                  :key="index"
                  :value="item2"
                  >{{ item2 }}</a-radio
                >
              </a-radio-group>
              <a-radio-group
                v-else
                v-model:value="formState.templateData[item.fieldName]"
              >
                <a-radio
                  v-for="(item2, index) in item.val.split(',')"
                  :key="index"
                  :value="item2"
                  >{{ item2 }}</a-radio
                >
              </a-radio-group>
            </div>

            <a-date-picker v-else />
          </a-form-item>
        </template>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="visible2"
      title="导入客户"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      :width="500"
      @ok="okDaoru"
    >
      <div style="margin-left: 20px">
        <a-upload
          :file-list="file"
          :remove="handleRemove"
          :before-upload="beforeUpload"
        >
          <a-button>
            <upload-outlined></upload-outlined>
            选择文件
          </a-button>
        </a-upload>
        <!-- <div style="color: red">(建议单次导入号码数量不要超过二十万条)</div> -->
        <div>
          *Excel文件必须是.csv或.xlsx扩展名, csv文件请用GBK或UTF-8格式保存
          <a @click="download" style="color: red">模板下载</a>
        </div>
      </div>
    </a-modal>
    <a-modal
      v-model:visible="visible3"
      title="转移"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      :width="500"
    >
      <a-select
        placeholder="请选择"
        style="width: 240px"
        v-model:value="fValue"
      >
        <a-select-option
          v-for="item in sub_account"
          :value="item.user_id"
          :key="item.user_id"
          >{{ item.username }}</a-select-option
        >
      </a-select>
    </a-modal>
    <a-modal
      v-model:visible="visible4"
      title="添加至营销任务"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      :width="500"
    >
      <a-select placeholder="请选择" style="width: 240px">
        <a-select-option value="1">教育培训</a-select-option>
        <a-select-option value="2">互联网金融</a-select-option>
        <a-select-option value="3">汽车制造</a-select-option>
      </a-select>
    </a-modal>
    <a-modal
      v-model:visible="visible5"
      title="通话记录"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      class="model-three"
      :width="1200"
      @ok="callOk"
    >
      <a-collapse
        v-model:activeKey="activeKey"
        :accordion="true"
        expandIconPosition="right"
        :bordered="false"
        @change="keyChange"
      >
        <a-collapse-panel :key="index" v-for="(item, index) in recordList">
          <template #header>
            <div :class="{ active: activeKey == index }">
              <label class="collapse-lab"> 呼叫时间：{{ item.calldate }}</label
              ><label class="collapse-lab">
                接听时间：{{ item.answerdate }}</label
              >
              <label class="collapse-lab">
                话术模板：{{ item.talk_temp_name }}</label
              >
              <label class="collapse-lab">通话时长：{{ item.bill }}秒</label>
            </div>
          </template>
          <CallLog :number="number" :records="records" @playVideo="playVideo" />
        </a-collapse-panel>
      </a-collapse>
    </a-modal>
    <a-modal
      v-model:visible="visible6"
      :title="play === 1 ? '退回公海' : '删除'"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      @ok="okDelete"
    >
      <p>{{ play === 1 ? "确认退回公海？" : "确认删除？" }}</p>
    </a-modal>
    <a-modal
      v-model:visible="visible7"
      title="导出字段"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      @ok="okdaochu"
    >
      <a-checkbox-group v-model:value="checkboxArr">
        <a-checkbox
          v-for="item in checkboxList"
          :value="item"
          :key="item"
          :disabled="item === '电话号码'"
          >{{ item }}</a-checkbox
        >
        <!-- <a-checkbox :disabled="true" value="电话号码">电话号码</a-checkbox>
        <a-checkbox value="公司名称">公司名称</a-checkbox>
        <a-checkbox value="详细地址">详细地址</a-checkbox>
        <a-checkbox value="联系人">联系人</a-checkbox>
        <a-checkbox value="性别">性别</a-checkbox>
        <a-checkbox value="意向标签">意向标签</a-checkbox> -->
      </a-checkbox-group>
      <!-- <json-excel
        :data="[{}]"
        :fields="{ '张麻子': '张麻子', '三点': '三点' }"
        type="csv"
        class="btn btn-default"
        name="filename.xls"
      >
        下载模板
      </json-excel> -->
    </a-modal>
    <a-modal
      v-model:visible="visible9"
      title="跟进记录"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      :width="800"
      @ok="followOk"
    >
      <div>
        <a-collapse v-model:activeKey="activeKey2" :accordion="true">
          <a-collapse-panel
            :header="item.username"
            v-for="(item, index) in fllowList"
            :key="index + 1 + ''"
          >
            <template #extra>{{ item.follow_date }}</template>
            <label class="dianhua" v-if="item.follow_way === 'face'"
              ><ColumnWidthOutlined /> 当面约谈</label
            >
            <label class="dianhua" v-else-if="item.follow_way === 'tel'"
              ><PhoneOutlined />电话交流</label
            >
            <label class="dianhua" v-else-if="item.follow_way === 'sms'"
              ><MailOutlined />短信沟通</label
            >
            <label class="dianhua" v-else-if="item.follow_way === 'net'"
              ><GlobalOutlined />网络沟通</label
            >
            <label class="dianhua" v-else-if="item.follow_way === 'visit'"
              ><ImportOutlined />来访接待</label
            >
            <label class="dianhua" v-else-if="item.follow_way === 'meeting'"
              ><TableOutlined />会议讨论</label
            >
            <label class="dianhua" v-else-if="item.follow_way === 'train'"
              ><FunnelPlotOutlined />培训研究</label
            >
            <label class="dianhua" v-else-if="item.follow_way === 'meal'"
              ><NotificationOutlined />商务餐饮</label
            >
            <label class="dianhua" v-else-if="item.follow_way === 'activity'"
              ><UsergroupAddOutlined />外出活动</label
            >
            <label class="dianhua" v-else-if="item.follow_way === 'other'"
              ><EllipsisOutlined />其它</label
            >
            <a-textarea :disabled="true" v-model:value="item.content" />
          </a-collapse-panel>
        </a-collapse>
        <a-button style="margin-top: 15px" @click="tianjia" type="primary"
          >添加跟进记录</a-button
        >
      </div>
    </a-modal>
    <a-modal
      v-model:visible="visible8"
      title="添加跟进记录"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      :width="670"
      @cancel="formGenjinRest"
      @ok="okGenjin"
    >
      <a-form :model="formGenjin">
        <a-form-item label="跟进方式">
          <a-select placeholder="请选择" v-model:value="formGenjin.follow_way">
            <a-select-option value="face">当面约谈</a-select-option>
            <a-select-option value="tel">电话交流</a-select-option>
            <a-select-option value="sms">短信沟通</a-select-option>
            <a-select-option value="net">网络沟通</a-select-option>
            <a-select-option value="visit">来访接待</a-select-option>
            <a-select-option value="meeting">会议讨论</a-select-option>
            <a-select-option value="train">培训研究</a-select-option>
            <a-select-option value="meal">商务餐饮</a-select-option>
            <a-select-option value="activity">外出活动</a-select-option>
            <a-select-option value="other">其它</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="跟进详情">
          <a-textarea placeholder="请输入" v-model:value="formGenjin.content" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs } from "@vue/reactivity";
import {
  DownOutlined,
  UploadOutlined,
  MailOutlined,
  PhoneOutlined,
  ColumnWidthOutlined,
  GlobalOutlined,
  ImportOutlined,
  TableOutlined,
  FunnelPlotOutlined,
  NotificationOutlined,
  UsergroupAddOutlined,
  EllipsisOutlined,
} from "@ant-design/icons-vue";
import {
  getList,
  postAdd,
  postDelete,
  postBack,
  postUpdate,
  postRecord,
  postImport,
  getSpeechList,
  getFollowList,
  postFollowInsert,
  postInsert,
  getRecordItem,
  // getExport,
} from "../../service/private";
import { onMounted } from "@vue/runtime-core";
import { message } from "ant-design-vue";
// import JsonExcel from "vue-json-excel/JsonExcel.vue";
import CsvExportor from "csv-exportor";
import { handleType } from "../../utils/function";
import CallLog from "../../components/CallLog.vue";
import {regTest} from '../../utils/regExp'
const columns = [
  {
    title: "公司名",
    dataIndex: "company_name",
  },
  {
    title: "联系人",
    dataIndex: "contact",
  },
  {
    title: "电话号码",
    dataIndex: "phone",
  },
  {
    title: "客户类型",
    dataIndex: "result_type",
    slots: {
      customRender: "result_type",
    },
  },
  {
    title: "地址",
    dataIndex: "address",
  },
  {
    title: "通话时长",
    dataIndex: "bill",
  },
  {
    title: "操作",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
    fixed: "right",
  },
];
export default {
  name: "Private",
  components: {
    DownOutlined,
    UploadOutlined,
    MailOutlined,
    PhoneOutlined,
    ColumnWidthOutlined,
    GlobalOutlined,
    ImportOutlined,
    TableOutlined,
    FunnelPlotOutlined,
    NotificationOutlined,
    UsergroupAddOutlined,
    EllipsisOutlined,
    CallLog,
  },
  setup() {
    const state = reactive({
      current: 1,
      total: undefined,
      value: undefined,
      value2: "1",
      visible1: false, //单个导入
      visible2: false, //批量导入
      visible3: false, //转移
      visible4: false, //添加至营销任务
      visible5: false, //通话记录
      visible6: false, //删除
      visible7: false, //导出字段
      visible8: false, //跟进记录
      visible9: false, //跟进记录
      file: [],
      customer_id: undefined,
      activeKey2: ["1"],
      data: [],
      selectedRowKeys: [],
      disabled: false,
      play: undefined,
      checkboxArr: ["电话号码"],
      checkboxList: [],
      sub_account: [],
      fValue: undefined,
      uuid: undefined,
      bill_min: undefined,
      bill_max: undefined,
      result_type: [],
      uuidArr: [],
      custom_field: [],
      requireArr: [],
      fllowList: [], //跟进记录列表
      recordList: [], //通话记录列表
      number: {},
      records: [],
      activeKey: ["0"],
    });
    // const activeKey = ref([0]);
    const formState = reactive({
      systemData: {},
      templateData: {},
    });
    const callOk = ()=>{
      state.visible5 = false
    }
    const followOk = ()=>{
      state.visible9 = false
    }
    const formRest = () => {
      state.customer_id = undefined;
      formState.systemData = {};
      formState.templateData = {};
    };
    const formGenjin = reactive({
      follow_way: undefined,
      content: undefined,
    });
    const formGenjinRest = () => {
      formGenjin.follow_way = undefined;
      formGenjin.content = undefined;
    };
    onMounted(async () => {
      getListFun();
      const res = await getSpeechList({
        access_token: sessionStorage.getItem("token"),
        distribution_id: 0,
      });
      console.log(res);
      const { list } = res.data.data;
      state.uuidArr = list.map((item) => {
        return {
          name: item.talk_temp_name,
          uuid: item.talk_temp_id,
        };
      });
    });
    const requestChange = () => {
      getListFun();
    };
    const getListFun = async () => {
      const params = {
        uuid: state.uuid,
        bill_min: state.bill_min,
        bill_max: state.bill_max,
        result_type: state.result_type.join(","),
      };
      switch (state.value2) {
        case "1":
          params.name = state.value;
          break;
        case "2":
          params.phone = state.value;
          break;
        case "3":
          params.contact = state.value;
          break;
      }
      const res = await getList({
        access_token: sessionStorage.getItem("token"),
        ...params,
        limit: 9,
        page: state.current,
      });
      console.log(res);
      state.data = res.data.data.customer.results;
      state.total = res.data.data.customer.total;
      state.sub_account = res.data.data.sub_account;
      state.custom_field = res.data.data.custom_field;

      state.custom_field.forEach((item) => {
        if (item.style == 0) {
          state.requireArr.push(item.fieldName);
        }
      });
      res.data.data.custom_field.forEach((item) =>
        state.checkboxList.push(item.label)
      );
    };

    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys;
    };
    const pageChange = (e) => {
      state.current = e.current;
      getListFun();
    };
    const daoRu = (e) => {
      console.log(e.key);
      if (e.key === "1") {
        state.visible1 = true;
        state.disabled = false;
      } else {
        state.visible2 = true;
      }
    };
    const handleRemove = () => {
      state.file = [];
    };

    const beforeUpload = (file) => {
      state.file = [file];
      return false;
    };
    const zhuanYi = () => {
      state.visible3 = true;
    };
    const genDuo = (e) => {
      if (e.key === "1") {
        state.visible4 = true;
      }
    };
    const callLog = (customer_id) => {
      state.visible5 = true;
      postRecordFun(customer_id);
    };
    const keyChange = (e) => {
      console.log(e);
      if (!e) return;
      e = Number(e);
      getRecordItemFun(state.recordList[e].id, state.recordList[e].job_id);
    };
    const postRecordFun = async (customer_id) => {
      const res = await postRecord({
        access_token: sessionStorage.getItem("token"),
        customer_id,
      });
      console.log(res);
      state.recordList = res.data.data;
      if(state.recordList.length>0){
        getRecordItemFun(state.recordList[0].id, state.recordList[0].job_id);
      }
    };
    const getRecordItemFun = async (id, job_id) => {
      state.records = [];
      const res = await getRecordItem({
        access_token: sessionStorage.getItem("token"),
        job_id,
        id,
      });
      console.log(res);
      state.number = res.data.data.number;
      // state.records = res.data.data.records;
      res.data.data.records.forEach((item) => {
        item.play = false;
        state.records.push(item);
        if (item.child) {
          item.child.forEach((item2) => {
            item2.play = false;
            state.records.push(item2);
          });
        }
      });
    };
    const playVideo = (index) => {
      state.records.forEach((item) => {
        item.play = false;
      });
      state.records[index].play = true;
    };
    const xinXi = (record) => {
      console.log(record);
      state.visible1 = true;
      state.disabled = true;
      state.customer_id = record.crm_customer_id;
      for (let key in record) {
        if (state.requireArr.includes(key)) {
          formState.systemData[key] = record[key];
        }
      }
      formState.templateData = JSON.parse(record.template_data);
    };
    const addCustomer = async () => {
      
      if (state.customer_id) {
         if (!formState.systemData.phone || !formState.systemData.phone.trim()) {
          message.warning("电话号码是必填项");
          return;
        }else if(!regTest(formState.systemData.phone)){
          message.warning('手机号格式有误')
          return
        }else if(!formState.systemData.contact || !formState.systemData.contact.trim()){
           message.warning("联系人是必填项");
          return;
        }else if(!formState.systemData.name || !formState.systemData.name.trim()){
           message.warning("客户名称是必填项");
          return;
        }else if(!formState.systemData.address || !formState.systemData.address.trim()){
           message.warning("地址是必填项");
          return;
        }
        const res = await postUpdate({
          access_token: sessionStorage.getItem("token"),
          customer_id: state.customer_id,
          systemData: JSON.stringify(formState.systemData),
          templateData: JSON.stringify(formState.templateData),
        });
        console.log(res);
        if (res.data.status == 100000) {
          message.success(res.data.msg);
          state.visible1 = false;
          formRest();
          getListFun();
        } else {
          message.error(res.data.msg);
        }
      } else {
      if (!formState.systemData.phone || !formState.systemData.phone.trim()) {
          message.warning("电话号码是必填项");
          return;
        }else if(!regTest(formState.systemData.phone)){
          message.warning('手机号格式有误')
          return
        }else if(!formState.systemData.contact || !formState.systemData.contact.trim()){
           message.warning("联系人是必填项");
          return;
        }else if(!formState.systemData.name || !formState.systemData.name.trim()){
           message.warning("客户名称是必填项");
          return;
        }else if(!formState.systemData.address || !formState.systemData.address.trim()){
           message.warning("地址是必填项");
          return;
        }
        const res = await postAdd({
          access_token: sessionStorage.getItem("token"),
          // ...formState,
          systemData: JSON.stringify(formState.systemData),
          templateData: JSON.stringify(formState.templateData),
        });
        console.log(res);
        if (res.data.status == 100000) {
          message.success(res.data.msg);
          state.visible1 = false;
          formRest();
          getListFun();
        } else {
          message.error(res.data.msg);
        }
      }
    };
    const deleteCustomer = (play) => {
      // console.log(state);
      if (state.selectedRowKeys.length === 0) {
        message.warning(`请至少选择一项${play === 1 ? "退回公海" : "删除"} `);
        return;
      }
      state.play = play;
      state.visible6 = true;
    };
    const okDelete = async () => {
      if (state.play === 1) {
        const res = await postBack({
          access_token: sessionStorage.getItem("token"),
          customer_id: state.selectedRowKeys.join(","),
        });
        console.log(res);
        if (res.data.status == 100000) {
          message.success(res.data.msg);
          state.visible6 = false;
          state.selectedRowKeys = [];
          state.play = undefined;
          getListFun();
        } else {
          message.error(res.data.msg);
        }
      } else {
        const res = await postDelete({
          access_token: sessionStorage.getItem("token"),
          customer_ids: state.selectedRowKeys,
          top: 1,
        });
        console.log(res);
        if (res.data.status == 100000) {
          message.success(res.data.msg);
          state.visible6 = false;
          state.selectedRowKeys = [];
          getListFun();
        } else {  
          message.error(res.data.msg);
        }
      }
    };
    // const daoChu = () => {};
    //下载导出模板
    const download = () => {
      state.visible7 = true;
    };
    const okdaochu = () => {
      console.log(state.checkboxArr);
      let header = state.checkboxArr;
      CsvExportor.downloadCsv(
        [{}],
        // ["zhangsan", "dfdf", "wewe"],
        { header },
        "模板.csv"
      );
    };
    // const muban = (arr) => {
    //   let obj = {};
    //   arr.forEach((item) => {
    //     obj[item] = item;
    //   });
    //   console.log(obj);
    //   return obj;
    // };
    const okDaoru = async () => {
      const formData = new FormData();
      formData.append("file", state.file[0]);
      formData.append("type", "private");
      formData.append("access_token", sessionStorage.getItem("token"));
      const res = await postImport(formData);
      if (res.data.status == 100000) {
        message.success(res.data.msg);
        state.visible2 = false;
        state.file = [];
        getListFun();
      } else {
        message.error(res.data.msg);
      }
    };
    const daoChu = async () => {
      if (state.selectedRowKeys.length === 0) {
        message.warning(`请选择要导出的数据`);
        return;
      }
      var a = document.createElement("a"); //创建a标签
      a.href =
        "/crm/custom_export?access_token=" +
        sessionStorage.getItem("token") +
        "&customer_ids=" +
        state.selectedRowKeys.join(",");
      // "&string=客户名称,电话号码"; //设置a标签的链接
      document.body.appendChild(a); //a标签添加到页面
      a.click(); //设置a标签触发单击事件
      document.body.removeChild(a); //移除a标签
      console.log(11111111111);
      // state.selectedRowKeys = []
      // const res = await getExport({
      //   access_token: sessionStorage.getItem("token"),
      //   customer_ids:state.selectedRowKeys.join(","),
      //   string:"客户名称,电话号码"
      // });
      // console.log(res);
      // if (res.data.status == 100000) {
      //   message.success(res.data.msg);
      //   state.selectedRowKeys = [];
      // } else {
      //   message.error(res.data.msg);
      // }
    };
    const okFenpei = async () => {
      const res = await postInsert({
        access_token: sessionStorage.getItem("token"),
        customer_ids: state.selectedRowKeys.join(","),
        to_user_id: state.fValue,
      });
      console.log(res);
      if (res.data.status == 100000) {
        message.success(res.data.msg);
        state.visible3 = false;
        state.fValue = undefined;
        state.selectedRowKeys = [];
        getListFun();
      } else {
        message.error(res.data.msg);
      }
    };
    const genJin = (customer_id) => {
      state.visible9 = true;
      state.customer_id = customer_id;
      getFollowListFun(customer_id);
    };
    const getFollowListFun = async (customer_id) => {
      const res = await getFollowList({
        access_token: sessionStorage.getItem("token"),
        customer_id: customer_id,
      });
      console.log(res);
      state.fllowList = res.data.data;
    };
    const tianjia = () => {
      state.visible8 = true;
    };
    const okGenjin = async () => {
      const res = await postFollowInsert({
        access_token: sessionStorage.getItem("token"),
        customer_id: state.customer_id,
        ...formGenjin,
      });
      console.log(res);
      if (res.data.status == 100000) {
        message.success(res.data.msg);
        state.visible8 = false;
        getFollowListFun(state.customer_id);
      } else {
        message.error(res.data.msg);
      }
    };
    return {
      onSelectChange,
      columns,
      ...toRefs(state),
      pageChange,
      daoRu,
      formState,
      handleRemove,
      beforeUpload,
      zhuanYi,
      genDuo,
      // activeKey,
      callLog,
      xinXi,
      formRest,
      addCustomer,
      deleteCustomer,
      okDelete,
      download,
      okdaochu,
      okDaoru,
      genJin,
      tianjia,
      requestChange,
      daoChu,
      formGenjinRest,
      okGenjin,
      formGenjin,
      okFenpei,
      handleType,
      playVideo,
      keyChange,
      callOk,
      followOk
    };
  },
};
</script>
<style>
.Private .ant-form .top .ant-form-item .ant-form-item-control,
.Private .ant-form .top .ant-form-item-control-input,
.Private .ant-form .top .ant-form-item-control-input-content {
  width: 200px;
  display: flex;
  align-items: center;
}
.modalAdd .Form .ant-form-item-label {
  min-width: 85px;
}
.model-three .ant-modal-body {
  overflow: auto;
}
</style>
<style scoped>
.Private {
  background-color: #fff;
  padding: 15px;
  overflow: auto;
  height: 100%;
}
.ant-form .top {
  display: flex;
  flex-wrap: wrap;
}
.ant-form .top .ant-form-item {
  margin-right: 20px;
}
.ant-form .ant-form-item {
  margin-bottom: 10px;
}

.table-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  align-items: center;
}
.ant-dropdown-trigger {
  margin-left: 15px;
}
.modalAdd .Form {
  /* margin: 10px 10px 0px 0px; */
  display: flex;
  flex-wrap: wrap;
}
.modalAdd .Form .ant-form-item {
  width: 280px;
  display: flex;
}

.modalAdd .Form .ant-form-item:nth-child(2n + 1) {
  margin-right: 30px;
}

.model-three .content {
  height: 400px;
  display: flex;
}
.model-three .content .content-left {
  flex: 1;
  margin-right: 15px;
  background-color: #fff;
}
.model-three .content .content-right {
  flex: 3;
  background-color: #fff;
}
.model-three .ant-modal-content {
  min-height: 764px;
}
.model-three .ant-modal-body {
  min-height: 656px !important;
}
.content-left {
  overflow: auto;
}
.content-left::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.content-left div span {
  margin-right: 4px;
  display: inline-block;
}
.content-left .ant-card {
  background-color: #f8f8f8;
}
.content-left .ant-card:nth-child(1) {
  margin-bottom: 15px;
}
.content-right {
  padding: 10px 15px;
  overflow: auto;
}
.robot .robot-top {
  margin-left: 54px;
  background: #e7f6fe;
  text-align: center;
  color: #2990e5;
  display: inline-block;
  padding: 3px 5px;
  margin-bottom: 3px;
}
.robot .robot-but {
  display: flex;
  align-items: center;
}
.robot-but-middle {
  height: 100%;
  display: flex;
  position: relative;
}
.yuyin,
.wenzi {
  background-color: #f1f1f1;
  min-height: 43px;
  display: flex;
  align-items: center;
  padding: 8px;
}
.yuyin {
  margin: 0 4px 0 8px;
  cursor: pointer;
  min-width: 36px;
}
.robot-but-middle::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 40%;
  left: 2px;
  border-top: 6px solid transparent;
  border-right: 6px solid #f1f1f1;
  border-bottom: 6px solid transparent;
}
.wenzi {
  margin-right: 20px;
  max-width: 300px;
}
.biaoshi {
  color: red;
}
.user {
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
  /* margin: 15px 0; */
}
.user .avator {
  width: 46px;
  height: 46px;
}
.user-but-middle {
  display: flex;
  height: 100%;
  margin-left: 20px;
}
.user-but-middle::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 40%;
  right: 48px;
  border-top: 6px solid transparent;
  border-left: 6px solid #9eea6a;
  border-bottom: 6px solid transparent;
}
.yuyin2,
.wenzi2 {
  background-color: #9eea6a;
  min-height: 43px;
  display: flex;
  align-items: center;
  padding: 8px;
}
.yuyin2 {
  margin: 0 8px 0 4px;
  cursor: pointer;
  min-width: 36px;
}
.wenzi2 {
  max-width: 300px;
}
.biaoshi2 {
  color: #666;
}

.dianhua {
  background-color: #96caf5;
  border-radius: 2px;
  padding: 2px 4px;
  margin-bottom: 7px;
  display: inline-block;
}
.collapse-lab {
  margin-right: 40px;
}
.active {
  color: #2990e5 !important;
}
</style>