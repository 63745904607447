import http from "../axios/http"
//私海客户列表
export let getList = (params) => {
    return http.get("/crm/private_customer_index", { params })
}
//添加客户
export let postAdd = (params) => {
    return http.post("/crm/private_customer_insert", params)
}
//删除客户
export let postDelete = (params) => {
    return http.post("/crm/private_customer_delete", params)
}
//私海退回公海
export let postBack = (params) => {
    return http.post("/crm/rollback_public", params)
}
//更新私海客户
export let postUpdate = (params) => {
    return http.post("/crm/private_customer_update", params)
}
//通话记录
export let postRecord = (params) => {
    return http.get("/crm/private_customer_record", {params})
}
//通话号码记录详情（已做）
export let getRecordItem = (params) => {
    return http.get("/number/record", { params })
}
//通话记录
export let postImport = (params) => {
    return http.post("/crm/custom_import", params)
}
//话术列表
export let getSpeechList = (params) => {
    return http.get("/speech/speech_list", { params })
}
//跟进记录
export let getFollowList = (params) => {
    return http.get("/crm/follow_list", { params })
}
//添加跟进记录
export let postFollowInsert = (params) => {
    return http.post("/crm/follow_insert",  params )
}
//私海转移客户
export let postInsert = (params) => {
    return http.post("/crm/customer_change_update",  params )
}
//导出
// export let getExport = (params) => {
//     return http.get("/crm/custom_export", { params })
// }
